@use 'variables' as *;

fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
    margin: 0;
    padding: 0;

    legend {
        text-align: center;
        margin-bottom: 15px;
    }

    input,
    textarea {
        margin: 0;
        padding: 10px;
        border-radius: 3px;
        border: none;
        box-shadow: 0 2px 10px rgba(56, 27, 0, 0.6);
    }

    textarea {
        resize: vertical;
        min-height: 75px;
        max-height: 300px;
    }
}

#consultation {
    background:
        linear-gradient(160deg,
            transparent 0% 70%,
            rgba(43, 3, 3, 0.4) 70% 100%),
        linear-gradient(230deg,
            transparent 0% 50%,
            rgba(43, 3, 3, 0.3) 50% 100%),
        linear-gradient(200deg,
            transparent 0% 70%,
            rgba(43, 3, 3, 0.4) 70% 100%),
        linear-gradient(230deg,
            transparent 0% 60%,
            rgba(233, 221, 221, 0.1) 60% 100%),
        linear-gradient(100deg,
            transparent 0% 90%,
            rgba(233, 221, 221, 0.1) 90% 100%);
}