@mixin transition($val) {
    transition: $val;
    -webkit-transition: $val;
    -o-transition: $val;
    -moz-transition: $val;
    -ms-transition: $val;
}

@mixin transform($val) {
    transform: $val;
    -webkit-transform: $val;
    -o-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
}

//animation: name duration timing-function delay direction iteration-count fill-mode play-state
@mixin animation($val) { 
    animation: $val;
    -webkit-animation: $val;
    -o-animation: $val;
    -moz-animation: $val;
    -ms-animation: $val;
}

@mixin animation-name($val) {
    animation-name: $val;
    -webkit-animation-name: $val;
    -o-animation-name: $val;
    -moz-animation-name: $val;
    -ms-animation-name: $val;
}

@mixin animation-duration($val) {
    animation-duration: $val;
    -webkit-animation-duration: $val;
    -o-animation-duration: $val;
    -moz-animation-duration: $val;
    -ms-animation-duration: $val;
}

@mixin animation-timing-function($val) {
    animation-timing-function: $val;
    -webkit-animation-timing-function: $val;
    -o-animation-timing-function: $val;
    -moz-animation-timing-function: $val;
    -ms-animation-timing-function: $val;
}

@mixin animation-delay($val) {
    animation-delay: $val;
    -webkit-animation-delay: $val;
    -o-animation-delay: $val;
    -moz-animation-delay: $val;
    -ms-animation-delay: $val;
}

@mixin animation-direction($val) {
    animation-direction: $val;
    -webkit-animation-direction: $val;
    -o-animation-direction: $val;
    -moz-animation-direction: $val;
    -ms-animation-direction: $val;
}

@mixin animation-iteration-count($val) {
    animation-iteration-count: $val;
    -webkit-animation-iteration-count: $val;
    -o-animation-iteration-count: $val;
    -moz-animation-iteration-count: $val;
    -ms-animation-iteration-count: $val;
}

@mixin animation-fill-mode($val) {
    animation-fill-mode: $val;
    -webkit-animation-fill-mode: $val;
    -o-animation-fill-mode: $val;
    -moz-animation-fill-mode: $val;
    -ms-animation-fill-mode: $val;
}

@mixin animation-play-state($val) {
    animation-play-state: $val;
    -webkit-animation-play-state: $val;
    -o-animation-play-state: $val;
    -moz-animation-play-state: $val;
    -ms-animation-play-state: $val;
}