@use 'mixin' as *;

@keyframes show-p {
    0% {
        opacity: 0;
        @include transform(translatey(30px));
    }

    100% {
        opacity: 1;
        @include transform(translatey(0));
    }
}

@keyframes show-h123 {
    0% {
        opacity: 0;
        @include transform(translatey(-50px));
    }

    100% {
        opacity: 1;
        @include transform(translatey(0));
    }
}

@keyframes show-h456 {
    0% {
        opacity: 0;
        @include transform(translatey(-50px));
    }

    100% {
        opacity: 1;
        @include transform(translatey(0));
    }
}

@keyframes show-img {
    0% {
        opacity: 0;
        @include transform(scale(0.9));
    }

    100% {
        opacity: 1;
        @include transform(scale(1));
    }
}

/*
@keyframes show-menu{
    0% {
        opacity: 0;
        @include transform(scale(0.9);
    }

    100% {
        opacity: 1;
        @include transform(scale(1);
    }
}*/